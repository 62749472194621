import React, { Component } from 'react'
import getYear from 'date-fns/getYear'
import getMonth from 'date-fns/getMonth'
import getDate from 'date-fns/getDate'
import getHour from 'date-fns/getHours'
import getMinute from 'date-fns/getMinutes'
import getSecond from 'date-fns/getSeconds'
import format from 'date-fns/format';
import { isValid } from "date-fns";
import axios from 'axios';
import Availability from './draftAvailability';
import DraftDateRange from './draftDateRange'

class DraftForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      employee: null,
      employeeName: '--Select--',
      //has extra 1000
      start_time: null,
      end_time: null,
      end_recur: null,
      location: '-',
      dot: {},
      open: false,
      render: 'form',
      days: [false, false, false, false, false, false, false],
      weekday: ['sun', 'mon', 'tue', 'wed', 'thur', 'fri', 'sat'],
      shifts: null,
      note: ''
    }
  }

  componentDidMount() {
    if (this.props.data.shifts) {
      this.setState({ shifts: this.getEmployeeShiftNumber(this.props.data.shifts) })
    }
    if (this.props.calendarEndDate) {
      this.setState({ end_recur: this.props.calendarEndDate })
    }
  }

  componentDidUpdate(prevProps) {
    let changedProps = (prevProps.start_time !== this.props.start_time) || (prevProps.end_time !== this.props.end_time) ||
      (prevProps.locations !== this.props.locations) || (prevProps.location !== this.props.location) || (prevProps.note !== this.props.note)
    if (changedProps) {
      // console.log(this.props.start_time)
      this.setState({
        start_time: this.props.start_time,
        end_time: this.props.end_time,
        location: this.props.location,
        shiftId: this.props.shiftId,
        employee: this.props.employee,
        note: this.props.note
      })


      //when a new time is passed from the calendar, check availability
      for (let index in this.props.users) {
        this.checkAvailability(this.props.users[index]);
      }

      this.closeSelections();
    }

    if (prevProps.calendarEndDate !== this.props.calendarEndDate) {
      this.setState({ end_recur: this.props.calendarEndDate })
    }

    if (prevProps.data.shifts !== this.props.data.shifts) {
      this.setState({ shifts: this.getEmployeeShiftNumber(this.props.data.shifts) })
    }
  }

  getEmployeeShiftNumber = (shifts) => {
    let list = {};
    if (shifts && shifts.length > 0) {
      shifts.forEach(shift => {
        if (shift.employee) {
          if (list[shift.employee.netid]) {
            list[shift.employee.netid] += (shift.end_time - shift.start_time) / 3600;
          } else {
            list[shift.employee.netid] = (shift.end_time - shift.start_time) / 3600;
          }
        }
      })
    }
    return list;
  }

  // change handler for input type time
  changeTimeHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    // time comes from input in format "hh:mm", so need to convert to javascript date
    let time = value.split(":")
    let base_date;
    if (name === 'start_time') {
      base_date = this.state.start_time ? this.state.start_time * 1000 : Date.now();
    } else {
      base_date = this.state.end_time ? this.state.end_time * 1000 : Date.now();
    }
    // adjust month value from Jan=1 to Jan=0 and set time as 11:59:59 pm
    let date = new Date(getYear(base_date), getMonth(base_date), getDate(base_date), time[0], time[1], 0)

    this.setState({ [name]: date / 1000 })
    if (this.state.employee) {
      // console.log(this.state.employee);
      this.checkAvailability(this.state.employee);
    }
  }

  // change handler for input type date
  changeDateHandler = (event) => {
    const value = event.target.value;

    // date comes from input in format "yyyy-mm-dd", so need to convert to javascript date
    let end = value.split("-");

    let start_time = this.state.start_time ? new Date(this.state.start_time * 1000) : Date.now();
    let end_time = this.state.end_time ? new Date(this.state.end_time * 1000) : Date.now();
    let start_date = new Date(end[0], end[1] - 1, end[2], getHour(start_time), getMinute(start_time), getSecond(start_time));
    let end_date = new Date(end[0], end[1] - 1, end[2], getHour(end_time), getMinute(end_time), getSecond(end_time));
    if (isValid(start_date) && isValid(end_date)) {
      this.setState({ start_time: start_date / 1000, end_time: end_date / 1000 })

      if (this.state.employee) {
        this.checkAvailability(this.state.employee);
      }
    }
  }

  changeNoteHandler = (event) => {
    const value = event.target.value;

    this.setState({ note: value })

    if (this.state.employee) {
      this.checkAvailability(this.state.employee);
    }
  }

  // generic change handler
  changeHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    console.log(`${name} | ${value}`)
    this.setState({
      [name]: value
    });
    if (this.state.employee) {
      this.checkAvailability(this.state.employee);
    }
  }

  changeEmployeeHandler = (name, value) => {
    if (value !== 'Available' && value !== '--Select--') {
      let user = value;
      if (this.state.shifts && this.state.shifts[user.netid]) {
        this.setState({ employeeName: (user.display_name || user.name) + " [ " + Math.round(this.state.shifts[user.netid] * 100) / 100 + " hr ]" })
      } else {
        this.setState({ employeeName: user.display_name || user.name });
      }
    } else {
      this.setState({ employeeName: value })
    }
    this.props.highlightEmployee(value);
  }

  //submit a new shift
  submitForm = (event) => {
    event.preventDefault();
    let shifts = [];

    let start_time = this.state.start_time;
    let end_time = this.state.end_time;
    let employee = this.state.employee;
    let days = this.state.days;

    //if try to add shift to an employee who is not available
    if (employee && this.state.dot[employee.netid] === 'dot-red') {
      console.log(this.state.dot[employee.netid])
      if (!window.confirm("This employee is not available. Do you still want to add schedule?")) { return }
    }

    if (!this.props.checkError(start_time, end_time)) {
      let start_time_to_date = new Date(this.state.start_time * 1000)
      let day = start_time_to_date.toString().substring(0, 3).toLowerCase();
      if (day === 'thu') { day = 'thur' }
      let index = this.state.weekday.indexOf(day);
      const end_recur = this.state.end_recur / 1000;
      const oneDay = 86400; //seconds in a day
      const oneWeek = oneDay * 7; //seconds in a week
      for (let i = 0; i < 7; i++) {
        let day_index = index + i;
        // console.log('i:' + i);
        if (days[day_index % 7]) {
          let start_date = start_time + i * oneDay;
          let end_date = end_time + i * oneDay;


          let lastInDLS = null; //was the last date in daylight savings
          while (start_date <= end_recur) {
            // console.log("date is: " + start_date)
            const inDLS = this.isDaylightSavings(start_date);
            if (lastInDLS !== null) {
              if (!inDLS && lastInDLS) {//(fall back)
                console.log("fall back")
                start_date = start_date + 3600
                end_date = end_date + 3600
              } else if (inDLS && !lastInDLS) {//(spring forward)
                start_date = start_date - 3600
                end_date = end_date - 3600
              }
            }
            lastInDLS = this.isDaylightSavings(start_date);
            shifts.push({
              employee: employee,
              start_time: start_date,
              end_time: end_date,
              location: this.state.location,
              group: this.props.group,
              note: this.state.note
            })
            //need to check DLS here
            start_date += oneWeek;
            end_date += oneWeek;
          }
        }
      }

      if (!days[index]) {
        shifts.push({
          employee: employee,
          start_time: start_time,
          end_time: end_time,
          location: this.state.location,
          group: this.props.group
        })
      }
      // console.log("SHIFTS!!!!")
      // console.log(shifts)

      this.resetState();
      this.props.addShiftsToDraft(shifts);
    }
  }

  //get an event info
  getEvent = () => {
    let event = {
      start_time: this.state.start_time / 1000,
      end_time: this.state.end_time / 1000,
      location: this.state.location,
      shiftId: this.state.shiftId,
      employee: this.state.employee,
      note: this.state.note
    };
    // console.log("Event: ", event);
    return event;
  }

  //edit a shift
  editForm = () => {
    if (!this.props.checkError(this.state.start_time, this.state.end_time)) {
      let event = this.getEvent();
      event.start_time = event.start_time * 1000;
      event.end_time = event.end_time * 1000;
      if (this.state.employee) {
        if (this.state.dot[this.state.employee.netid] === 'dot-red') {
          if (window.confirm("This employee is not available. Do you still want to add schedule?")) {
            this.props.updateDraftShift(event);
            this.resetState();
          }
        } else {
          this.props.updateDraftShift(event);
          this.resetState();
        }
      } else {
        this.props.updateDraftShift(event);
        this.resetState();
      }
    }
  }

  //delete a shift
  deleteForm = () => {
    this.props.deleteDraftShift(this.getEvent());
    this.resetState();
  }

  //checks whether an employee has availability and has open schedule
  checkAvailability = (user) => {
    let free = false;
    let dot = {}
    if (this.state.dot) {
      dot = this.state.dot;
    }
    dot[user.netid] = 'dot-red';
    if (this.state.start_time && this.state.end_time) {
      let start_time_to_date = new Date(this.state.start_time * 1000)
      let end_time_to_date = new Date(this.state.end_time * 1000)
      let start_time = getHour(start_time_to_date) * 100 + getMinute(start_time_to_date);
      let end_time = getHour(end_time_to_date) * 100 + getMinute(end_time_to_date);
      let day = start_time_to_date.toString().substring(0, 3).toLowerCase();
      if (day === 'thu') { day = 'thur' }
      let startTimes = []
      let endTimes = []
      if (user.weekly_availability && user.weekly_availability[day]) {
        let availability = user.weekly_availability[day];
        availability.forEach(time => {
          startTimes.push(time.start)
          endTimes.push(time.end)
        });
      }
      if (user.weekly_maybe && user.weekly_maybe[day]) {
        let maybe_availability = user.weekly_maybe[day];
        maybe_availability.forEach(time => {
          let start = time.start
          let end = time.end
          let index = startTimes.indexOf(end);
          if (index !== -1) {
            end = endTimes[index]
          }
          index = endTimes.indexOf(start);
          if (index !== -1) {
            start = startTimes[index]
          }
          if (start <= start_time && end >= end_time) {
            dot[user.netid] = 'dot-yellow';
            free = true;
          }
        });
      }
      if (user.weekly_availability && user.weekly_availability[day]) {
        let availability = user.weekly_availability[day];
        availability.forEach(time => {
          if (time.start <= start_time && time.end >= end_time) {
            dot[user.netid] = 'dot-green';
            free = true;
          }
          startTimes.push(time.start)
          endTimes.push(time.end)
        });
      }
      if (user.weekly_maybe && user.weekly_maybe[day]) {
        let maybe_availability = user.weekly_maybe[day];
        maybe_availability.forEach(time => {
          if (time.start <= start_time && time.end >= end_time) {
            dot[user.netid] = 'dot-yellow';
            free = true;
          }
        });
      }
      if (free) {
        axios.get('shifts/find_time_fc/' + (this.state.start_time / 1000) + '/' + (this.state.end_time / 1000) + '/' + user.netid)
          .then((response) => {
            // console.log(response.data);
            if (response.data.length < 1 || (response.data.length === 1 && response.data[0].shiftId === this.state.shiftId)) {
              this.setState({ dot: dot });
              return true;
            } else {
              dot[user.netid] = 'dot-red';
              this.setState({ dot: dot });
              return false;
            }
          }).catch(function (err) {
            console.log(err)
          });
      }
    }
    this.setState({ dot: dot });
    return false;
  }

  //shows the options
  openSelections = () => {
    for (let index in this.props.users) {
      this.checkAvailability(this.props.users[index]);
    }
    this.setState({ open: !this.state.open })
  }

  closeSelections = () => {
    this.setState({ open: false })
  }

  //allows changes made to the customized select/options
  handleSelection = (event) => {
    const value = event.target.getAttribute('value');
    if (value !== 'open') {
      let user = this.props.data.users.filter(obj => { return obj.netid === value })[0]
      this.setState({ employee: user });
    } else {
      this.setState({ employee: null })
    }
  }

  //allows changes made to the days checkboxes
  handleCheckboxChange = (event) => {
    let day = event.target.name;
    let index = this.state.weekday.indexOf(day);
    let days = this.state.days;
    days[index] = !days[index];
    this.setState({ days: days });
  }

  //if it's adding, checkbox
  checkbox = () => {
    if (this.props.edit === 'Add') {
      let checkboxes = [];
      let days = this.state.days;
      this.state.weekday.forEach((day, index) => {
        checkboxes.push(<label className="checkbox" key={day}><input name={day} type="checkbox" checked={days[index]} onChange={this.handleCheckboxChange} /> {day}</label>)
      });
      return (
        <div className="field">
          <div className="control">
            <label className="label">Repeat on</label>
            {checkboxes}
          </div>
        </div>
      )
    }
  }

  //shows buttons like add, save, cancel and delete
  buttonGroup = () => {
    let delBtn = <DraftButton className="button" type="button" onClick={this.deleteForm} text="Delete" />
    let addBtn = <DraftButton className="button is-link" type="button" onClick={this.submitForm} text="Add" />
    let saveBtn = <DraftButton className="button is-link" type="button" onClick={this.editForm} text="Save" />
    let publishBtn = <DraftButton className="button is-link" type="button" onClick={this.props.publishSingle.bind(this, this.props.shiftId)} text="Publish" />
    let unPublishBtn = <DraftButton className="button" type="button" onClick={this.props.unpublishSingle.bind(this, this.props.shiftId)} text="UnPublish" />
    if (this.props.edit === 'Add') {
      delBtn = null;
      saveBtn = null;
      publishBtn = null;
      unPublishBtn = null;
    } else {
      addBtn = null;
    }

    return (
      <div className="buttons is-grouped">
        {addBtn}
        {saveBtn}
        {publishBtn}
        {unPublishBtn}
        <button className="button is-light" type="button" onClick={this.resetState.bind(this)}>Cancel</button>
        {delBtn}
      </div>
    )
  }

  //create a time slot on the form 
  createShift = () => {
    // console.log("start time", this.state.start_time, new Date(this.state.start_time))
    // console.log("end time", this.state.end_time, new Date(this.state.end_time))
    // console.log("start schedule", this.props.data.start_time, new Date(this.props.data.start_time))
    // console.log("end schedule", this.props.data.end_time, new Date(this.props.data.end_time))

    let name = 'Open';
    if (this.state.employee) {
      if (this.state.shifts && this.state.shifts[this.state.employee.netid]) {
        name = (this.state.employee.display_name || this.state.employee.name) + " [ " + Math.round(this.state.shifts[this.state.employee.netid] * 100) / 100 + "hr ]"
      } else {
        name = this.state.employee.display_name || this.state.employee.name
      }
    }
    return (
      <form>
        {/* <h2 className="title is-4">Time Sheet</h2> */}
        <h2 className="title">Time Sheet</h2>

        <div className="field">
          <h3 className="label">Date</h3>
          <div className="control">
            <label> Start: <input className='input' name='start_day' type="date"
              value={this.state.start_time && isValid(new Date(this.state.start_time * 1000)) ? format(this.state.start_time * 1000, "yyyy-MM-dd") : ''}
              onChange={this.changeDateHandler}
              // this is to make sure the time is within draft schedule time
              min={this.props.data.start_time && isValid(new Date(this.props.data.start_time * 1000)) ? format(this.props.data.start_time * 1000, "yyyy-MM-dd") : ''}
              max={this.props.data.end_time && isValid(new Date(this.props.end_time * 1000)) ? format(this.props.data.end_time * 1000, "yyyy-MM-dd") : ''} /></label>
          </div>
        </div>

        <div className="field">
          <div className="control">
            <h3 className="label">Time</h3>
            <label> From: <input className='input' name='start_time' type="time" step="900"
              value={this.state.start_time && isValid(new Date(this.state.start_time * 1000)) ? format(this.state.start_time * 1000, "HH:mm") : ''}
              onChange={this.changeTimeHandler} /></label>
            <label> To: <input className='input' name='end_time' type="time" step="900"
              value={this.state.end_time && isValid(new Date(this.state.end_time * 1000)) ? format(this.state.end_time * 1000, "HH:mm") : ''}
              onChange={this.changeTimeHandler} /></label>
          </div>
        </div>

        {this.checkbox()}

        <div className="field">
          <label className="label">Location: </label>
          <div className="control">
            <div className="select">
              <select name="location" value={this.state.location} onChange={this.changeHandler}>
                {this.mapLocations()}
              </select>
            </div>
          </div>
        </div>

        <div className="field">
          <label className="label">Assign To: </label>
          <div className="control">
            <div className="select custom-select-wrapper" onClick={this.openSelections}>
              <div className={this.state.open ? "custom-select open" : "custom-select"}>
                <p> {name}</p>
                <div className="custom-options">
                  <p className="custom-option" key="open" value='open' onClick={this.handleSelection.bind(this)}>
                    <span className='dot-green' /> Open
                  </p>
                  {this.mapUsers()}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="field">
          <label className="label">Note: </label>
          <div className="control">
            <input className='input' name='note'
              value={this.state.note ? this.state.note : ''}
              onChange={this.changeNoteHandler} />
          </div>
        </div>
        {this.buttonGroup()}

      </form>
    )
  }

  //populate dropdown for locations based off the workGroup collection
  mapLocations = () => {
    if (this.props.locations) {
      let options = []
      for (let i = 0; i < this.props.locations.length; i++) {
        const divStyle = {
          color: this.props.colorCodes[this.props.locations[i]]
        }
        const location = this.props.locations[i]

        options.push(<option style={divStyle} key={location} value={location}> {location}</option>)

      }
      return (options)
    }
  }

  //populate dropdown for users
  mapUsers = () => {
    let options = []
    if (this.props.users) {
      for (let index in this.props.users) {
        let user = this.props.users[index];
        let count;
        if (this.state.shifts && this.state.shifts[user.netid]) {
          count = "[ " + Math.round(this.state.shifts[user.netid] * 100) / 100 + " hr ]";
        }
        options.push(<p className={"custom-option"} key={user.netid} value={user.netid} onClick={this.handleSelection.bind(this)}>
          <span className={this.state.dot[user.netid]} /> {user.display_name || user.name} {count}
        </p>)
      }
    }
    return (options)
  }

  resetState() {
    this.props.resetFresh();
    this.setState({
      start_time: null,
      end_time: null,
      employee: null,
      employeeName: '--Select--',
      location: this.props.locations ? this.props.locations[0] : '-',
      days: [false, false, false, false, false, false, false],
      note: ''
    })

    for (let index in this.props.users) {
      this.checkAvailability(this.props.users[index]);
    }
    this.props.removeAllEventHighlight();
  }

  //change the render tab name
  handleClick(compName, e) {
    this.setState({ render: compName });
    if (compName === "range") {
      this.props.highlightEmployee("--Select--");
    }
  }



  isDaylightSavings = (epoch) => {
    ///deal with daylight savings, courtesy of https://stackoverflow.com/questions/11887934/how-to-check-if-dst-daylight-saving-time-is-in-effect-and-if-so-the-offset
    Date.prototype.stdTimezoneOffset = function () {
      var jan = new Date(this.getFullYear(), 0, 1);
      var jul = new Date(this.getFullYear(), 6, 1);
      return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
    }

    Date.prototype.isDstObserved = function () {
      return this.getTimezoneOffset() < this.stdTimezoneOffset();
    }
    const date = new Date(epoch)
    // console.log(date)
    // console.log("return " + date.isDstObserved())
    return date.isDstObserved()

  }

  //rerender the page
  switchTab() {
    switch (this.state.render) {
      default: return this.createShift()
      case 'time': return <Availability users={this.props.users} changeEmployeeHandler={this.changeEmployeeHandler}
        start_time={this.props.data.start_time} end_time={this.state.end_recur} highlightedEmployee={this.props.highlightedEmployee}
        shifts={this.state.shifts} isUnavailability={this.props.isUnavailability} employeeName={this.state.employeeName} />
      case 'range': return <DraftDateRange getSchedule={this.props.getSchedule} updateCalDateRange={this.props.updateCalDateRange} scheduleId={this.props.data.scheduleId} start_time={this.props.data.start_time} end_time={this.props.data.end_time} />
    }
  }

  render() {
    let label = "Availability";
    if (this.props.isUnavailability) {
      label = "Unavailability"
    }
    return (
      <div className="card show-overflow">
        <div className="card-content">
          <div className="tabs">
            <ul>
              <li className={this.state.render === 'form' ? 'is-active' : null} onClick={this.handleClick.bind(this, 'form')}><a href="#/">Time Sheet</a></li>
              <li className={this.state.render === 'time' ? 'is-active' : null} onClick={this.handleClick.bind(this, 'time')}><a href="#/">{label}</a></li>
              <li className={this.state.render === 'range' ? 'is-active' : null} onClick={this.handleClick.bind(this, 'range')}><a href="#/">Schedule Range</a></li>

            </ul>
          </div>
          {this.switchTab()}
        </div>
      </div>
    );
  }
}




/////// SUB COMPONENTS /////// 
// button sub-componet
const DraftButton = props => {
  return <button className={props.className} type={props.type} onClick={props.onClick}>{props.text}</button>
}

export default DraftForm;