import React, { Component } from 'react';
import axios from 'axios';
import NewDraft from './newDraft';
import Flash from '../common/flash';
import { checkGroups } from '../helpers/checkGroupName';
import DraftIndex from '../scheduleDrafts/draftIndex';

Date.prototype.getWeekNumber = function () {
  var d = new Date(Date.UTC(this.getFullYear(), this.getMonth(), this.getDate()));
  d.setUTCDate(d.getUTCDate() - d.getUTCDay());
  var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  return Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
};

class ManageScheduleIndex extends Component {
  constructor(props) {
    super();
    this.state = { group: null, draft: null, drafts: null, newOrExist: null }
  }

  componentDidMount() {
    //check for correct groups (creates flash error if group name has been changed)
    checkGroups().then((response) => {
      console.log(response)
      if (response.error) { this.setFlash(("SYS!:" + response.error), false); }
    });

    let groups = localStorage.getItem('group').split(",");
    if (groups[0]) {
      this.setState({ newOrExist: "new", group: groups[0] })
      this.getGroupDrafts(groups[0]);
    }
  }

  componentDidUpdate() {
    this.showCalendar();
  }

  changeHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      [name]: value,
      error: false,
      success: false
    });
    if (name === "newOrExist" && value === "new") {
      this.setState({ draft: null })
    }
    else if (name === "newOrExist") {
      this.getSchedule(value)
    }
    else if (name === "group") {
      this.setState({ newOrExist: "new", draft: null }, () => { this.getGroupDrafts(value) })
    }
  }

  setFlash = (error, success) => {
    console.log("SET FLASH");
    this.setState({ error: error, success: success });
  }

  closeFlash = () => {
    this.setState({ error: false, success: false })
  }

  //show error/success message
  flash = () => {
    if (this.state.error) {
      let errTitle = "Incomplete Form";
      let errMessage = this.state.error.toString();

      //check for systen error
      if (errMessage.slice(0, 5) === "SYS!:") {
        // console.log("SLICE: ", errMessage.slice(0,5));
        errTitle = "System Error";
        errMessage = errMessage.slice(5, errMessage.length);
      }

      return (
        <Flash
          type="error"
          title={errTitle}
          messages={[errMessage]}
          closeFlash={this.closeFlash}
        />
      );
    }

    if (this.state.success) {
      return (
        <Flash
          type="success"
          title="Success"
          messages={this.state.success}
          closeFlash={this.closeFlash}
        />
      );
    }
  };

  makeNewDraft = () => {
    if (this.state.newOrExist === "new" || this.state.newOrExist === 'duplicate')
      return <div><NewDraft redirectToNewDraft={this.redirectToNewDraft} group={this.state.group} setFlash={this.setFlash}
        draft={this.state.draft} addShifts={this.addShifts} /></div>
  }

  duplicateDraft = () => {
    this.setState({ newOrExist: 'duplicate' });
    this.makeNewDraft();
  }


  groupOptions = () => {
    var options = []
    let groups = localStorage.getItem('group').split(",");
    groups.forEach((group) =>
      options.push(
        <option key={group} className="capitalize-me" value={group}>{group}</option>
      )
    )
    return options
  }

  getSchedule = (scheduleId) => {
    axios.get("schedule/draft/find/" + scheduleId).then((response) => {
      this.setState({ draft: response.data, newOrExist: scheduleId });
    }).catch(function (err) {
      console.log(err)
    });
  }

  addShifts = (scheduleName) => {
    axios.get(`schedule/findByScheduleName/${this.state.group}/${scheduleName}`)
      .then((response) => {
        const draft = response.data;
        console.log(draft);
        let oldScheduleStart = parseInt(this.state.draft.start_time);
        let oldScheduleStartDate = new Date(oldScheduleStart * 1000);
        let oldYear = oldScheduleStartDate.getFullYear();
        let oldISOWeek = oldScheduleStartDate.getWeekNumber();
        // let oldScheduleEnd = parseInt(this.state.draft.end_time);
        // let oldScheduleEndDate = new Date(oldScheduleEnd * 1000);
        let newScheduleStart = parseInt(draft.start_time);
        let newScheduleStartDate = new Date(newScheduleStart * 1000);
        let newISOWeek = newScheduleStartDate.getWeekNumber();
        let newScheduleEnd = parseInt(draft.end_time);
        // let newScheduleEndDate = new Date(newScheduleEnd * 1000);
        // In case the new schedule is in a different year
        let newYear = newScheduleStartDate.getFullYear();
        let diff = (newISOWeek - oldISOWeek) * 604800;
        if (newYear > oldYear) {
          diff = (newISOWeek + 51 * (newYear - oldYear) - oldISOWeek) * 604800;
        }
        let shifts = this.state.draft.shifts;
        let newShifts = [];
        // console.log("==========schedule info===========")
        // console.log(oldScheduleStartDate, '-', oldScheduleEndDate)
        // console.log(newScheduleStartDate, '-', newScheduleEndDate)
        // console.log(`old iso week ${oldISOWeek}, new iso week ${newISOWeek}`);
        // console.log("diff", diff)
        for (let i = 0; i < shifts.length; i++) {
          // console.log("shifts", i)
          let oldStartTime = shifts[i].start_time;
          // let oldStartDate = new Date(oldStartTime*1000)
          let oldEndTime = shifts[i].end_time;
          // let oldEndDate = new Date(oldEndTime*1000)
          let newStartTime = oldStartTime + diff;
          // let newStartDate = new Date(newStartTime*1000);
          let newEndTime = oldEndTime + diff;
          // let newEndDate = new Date(newEndTime*1000);
          // console.log(`old shifts: ${oldStartDate.toISOString()} - ${oldEndDate.toISOString()}`)
          // console.log(`new shifts: ${newStartDate.toISOString()} - ${newEndDate.toISOString()}`)
          // console.log(`new schedule: ${newScheduleStartDate} - ${newScheduleEndDate}`)
          // only copy shifts if they are in the new schedule range
          if (newStartTime >= newScheduleStart && newStartTime < newScheduleEnd &&
            newEndTime > newScheduleStart && newEndTime <= newScheduleEnd
            && newStartTime < newEndTime) {
            console.log("shifts in new range");
            newShifts.push({
              scheduleId: draft.scheduleId,
              employee: shifts[i].employee?.name === "undefined undefined" ? null : shifts[i].employee,
              location: shifts[i].location,
              group: shifts[i].group,
              start_time: newStartTime,
              end_time: newEndTime,
              prettyStart: new Date(newStartTime * 1000),
              prettyEnd: new Date(newEndTime * 1000)
              //note
            })
          }
        }
        // console.log(newShifts);
        if (newShifts.length > 0) {
          // console.log(newShifts);
          let formData = new FormData();
          formData.append("newShifts", JSON.stringify(newShifts));

          axios.post("schedule/add_shifts/" + draft.scheduleId,
            formData,
            { headers: { 'content-type': 'application/form-data' } }
          ).then(() => {
            this.setFlash(false, ["Your schedule has been created!"]);
            this.redirectToNewDraft(scheduleName)
          }).catch(function (err) { console.log(err) });
        } else {
          this.setFlash(false, ["Your schedule has been created!"]);
          this.redirectToNewDraft(scheduleName)
        }
      })
      .catch(function (err) {
        console.log(err)
      });
  }

  //get info for all groups drafts. scheduleName is used to redirect after a new draft has been created
  getGroupDrafts = (group, scheduleName) => {
    axios.get("schedule/drafts/" + group).then((response) => {
      if (!this.state.newOrExist) { this.setState({ newOrExist: "new" }) }
      this.setState({ drafts: response.data });
      if (scheduleName) { this.handleRedirect(scheduleName) }
      else if (response.data.length > 0) {
        const draft = response.data[0];
        this.handleRedirect(draft.scheduleName);
      }
    }).catch(function (err) {
      console.log(err)
    });
  }

  //set the dropdown and show the calender on a fresh draft
  handleRedirect = (scheduleName) => {
    const draft = this.state.drafts.find(d => d.scheduleName === scheduleName);
    document.getElementById('new-or-exist').value = draft.scheduleId;
    this.getSchedule(draft.scheduleId);
  }

  redirectToNewDraft = (scheduleName) => {
    this.getGroupDrafts(this.state.group, scheduleName)
  }

  //show either the weekly or Draft Calendar
  showCalendar = () => {
    if (this.state.newOrExist === 'new' || this.state.newOrExist === 'duplicate' || !this.state.draft) { return }
    // console.log(this.state.draft)
    return <DraftIndex data={this.state.draft} getSchedule={this.getSchedule} group={this.state.group} />
  }

  deleteSchedule = () => {
    if (window.confirm(`Are you sure you want to delete ${this.state.draft.scheduleName}?`)) {
      // console.log(this.state.draft);
      axios.post("schedule/draft/delete_schedule/" + this.state.draft.scheduleId)
        .then((response) => {
          this.getGroupDrafts(this.state.group);
          this.setState({ draft: null, newOrExist: 'new', success: ['Deleted schedule!'] });
        }).catch(function (err) {
          console.log(err)
        });
    }
  }

  render() {
    // console.log("schedule id:", this.state.draft?.scheduleId)
    let deleteScheduleButton = (this.state.newOrExist && this.state.newOrExist !== 'new') ?
      <DeleteSchedule className="button is-light" type="button" onClick={this.deleteSchedule} text="Delete Schedule" /> : ''
    let duplicateScheduleButton = (this.state.newOrExist && this.state.newOrExist !== 'new') ?
      <DuplicateSchedule className="button is-light" type="button" onClick={this.duplicateDraft} text="Duplicate Schedule" /> : ''
    return (
      // <div className="container is-fluid">
      <div className="container">
        {/* <h1 className="title my-5">Manage Schedule</h1> */}
        <h1 className="title">Manage Schedule</h1>
        <div className="field is-grouped">
          <GroupSelect group={this.state.group} changeHandler={this.changeHandler} groupOptions={this.groupOptions} />
          <SavedDrafts newOrExist={this.state.newOrExist} changeHandler={this.changeHandler} drafts={this.state.drafts} />
          {deleteScheduleButton}
          {duplicateScheduleButton}
        </div>
        {this.flash()}
        {this.makeNewDraft()}
        {this.showCalendar()}
      </div>
    );
  }
}


export default ManageScheduleIndex;

// component helpers
function GroupSelect(props) {
  return (
    <div className="control">
      <label className="label">Group</label>
      <div className="select" onChange={props.changeHandler}>
        <select defaultValue={props.group} name="group">
          <option disabled="disabled" value="select_a_group" hidden="hidden">-- Select a Group --</option>
          {props.groupOptions()}
        </select>
      </div>
    </div>
  )
}

function SavedDrafts(props) {
  if (props.drafts) {
    let options = []
    let select =
      <div className="control">
        <label className="label">Select Schedule to Edit</label>
        <div className="select">
          <select id="new-or-exist" name="newOrExist" onChange={props.changeHandler} value={props.newOrExist ? props.newOrExist : 'new'}>
            <option key={"New"} value="new" >-- New Schedule --</option>
            {options}
          </select>
        </div>
      </div>
    props.drafts.forEach((draft, index) =>
      options.push(
        <option key={index} value={draft.scheduleId}>{draft.scheduleName}</option>
      )
    )
    return select
  } else {
    return null
  }
}

// component helpers
function DeleteSchedule(props) {

  return (
    <div className="control">
      <label className="label">Action</label>
      <button className={props.className} type={props.type} onClick={props.onClick}>{props.text}</button>
    </div>
  )
}

// component helpers
function DuplicateSchedule(props) {
  return (
    <div className="control">
      <label className="label">Action</label>
      <button className={props.className} type={props.type} onClick={props.onClick}>{props.text}</button>
    </div>
  )
}

