import React, { Component } from 'react';
import axios from 'axios';
import format from 'date-fns/format';

class DraftDateRange extends Component {

  constructor(props) {
      super(props);
      this.state = {
        start_date: format(this.props.start_time * 1000, "yyyy-MM-dd"),
        end_date: format(this.props.end_time * 1000, "yyyy-MM-dd")
      }
  }
 
  submitForm = () => {
    const original_date = new Date(this.state.start_date);
    const original_end = new Date(this.state.end_date);
    const start_date = new Date(original_date.getFullYear(), original_date.getMonth(), original_date.getDate() + 1, 0, 0, 0) / 1000;
    const end_date = new Date(original_end.getFullYear(), original_end.getMonth(), original_end.getDate() + 1, 23, 59, 59) / 1000;
    const body = {start_time: start_date, end_time: end_date}
    axios.post("schedule/updateDateRange/" + this.props.scheduleId, body)
      .then(response => {
        this.props.updateCalDateRange(start_date, end_date);
        this.props.getSchedule(this.props.scheduleId);
      }).catch((error) => {
        console.log(error)
      });
  }

  updateDate = (e) => {
    const name = e.target.name
    const value = e.target.value
    if(name === "start_date"){
      this.setState({"start_date": value})
    }else{
      this.setState({"end_date": value})
    }
  }

  render() {
    return (
      <div>
        <p>start: {this.props.start_time}</p>
        <p>end: {this.props.end_time}</p>
        <div className="field">
          <label className="label">Schedule Start</label>
          <div className="control">
            <input 
              className='input' 
              name='start_date' 
              type="date" 
              value={this.state.start_date}
              onChange={this.updateDate}
            />
          </div>
        </div>

        <div className="field">
          <label className="label">Schedule End</label>
          <div className="control">
           <input 
            className='input' 
            name='end_date' 
            type="date" 
            value={this.state.end_date} 
            onChange={this.updateDate}
            />
          </div>
        </div>
        <button className="button" type='button' onClick={this.submitForm}>Update Date Range</button>
      </div>
    );
  }
}

export default DraftDateRange;